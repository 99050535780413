import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-download',
    templateUrl: './app-download.component.html',
    styleUrls: ['./app-download.component.scss']
})
export class AppDownloadComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }
    
    appDownloadImage: Image[] = [
        {
            img: 'assets/img/why-choose.jpeg'
        }
    ]
    appDownloadContent: Content[] = [
        {
            title: 'Download the app today.',
            paragraph1: 'Sentit allows you to send money swiftly, securely, and nearly for free, with a three-second confirmation time.',
            button1: 'assets/img/store/1.png',
            button2: 'assets/img/store/2.png'
        }
    ]

}
class Image {
    img : string;
}
class Content {
    title : string;
    paragraph1 : string;
    button1 : string;
    button2 : string;
}
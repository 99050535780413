<app-products-banner></app-products-banner>

<app-products-about></app-products-about>

<app-products-features></app-products-features>

<app-products-specification></app-products-specification>

<app-products-discover></app-products-discover>

<app-products-users></app-products-users>

<app-products-gallery></app-products-gallery>

<app-products-faq></app-products-faq>

<app-products-feedback></app-products-feedback>

<app-products-newsletter></app-products-newsletter>

<app-other-products></app-other-products>

<app-products-contact></app-products-contact>
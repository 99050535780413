<section id="features" class="overview-area ptb-100">
    <div class="container">
        <div class="section-title" *ngFor="let sectionTitleContent of sectionTitle;">
            <h2>{{sectionTitleContent.title}}</h2>
            <p>{{sectionTitleContent.paragraphText}}</p>
        </div>

        <div class="overview-item" *ngFor="let Content of overviewContent;">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="overview-content">
                        <span class="number">{{Content.number}}</span>
                        <h3>{{Content.title}}</h3>
                        <p>{{Content.paragraph}}</p>
                        <ul>
                            <li *ngFor="let List of Content.featuresList"><i class="{{List.itemIcon}}"></i> {{List.item}}</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="overview-img">
                        <img [src]="Content.img" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
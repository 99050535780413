<section class="fun-facts-area pt-100 pb-70 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-sm-6 col-md-3" *ngFor="let Content of funFacts;">
                <div class="single-fun-fact">
                    <div class="icon">
                        <i class="{{Content.icon}}"></i>
                    </div>
                    <h3>{{Content.title}}</h3>
                    <p>{{Content.subTitle}}</p>
                </div>
            </div>
        </div>
    </div>
</section>
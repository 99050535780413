<!-- <div class="copy-right">
    <div class="container">
        
        <p *ngFor="let Text of copyrightText;"><i class="las la-copyright"></i>{{Text.text}}</p>
    </div>
</div> -->

<!-- Site footer -->
<footer class="site-footer">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <h6>About</h6>
                <p class="text-justify">Sentit.io is a Blockchain wallet that places its emphasis on cross-border payments and operates on a non-custodial model. This wallet, known as SendIt, presents a straightforward yet influential solution for transferring money via email. Utilizing the Stellar Blockchain, Sentit is capable of executing transactions with swiftness, security, and minimal fees.
                </p>
            </div>

            <div class="col-xs-6 col-md-3">
                <h6>Categories</h6>
                <ul class="footer-links">
                    <li><a href="#">FAQ</a></li>
                    <li><a href="https://sentit.medium.com/" target="-">Blog</a></li>
                    <!-- <li><a href="#contact">Contact</a></li> -->
                    <li><a href="https://sentit.io/policy" target="_">Privacy Policy</a></li>
                    <li><a href="https://sentit.io/terms" target="_">Terms of Service</a></li>
                </ul>
            </div>

            <div class="col-xs-6 col-md-3">
                <h6>Reach Out</h6>
                <ul class="footer-links">
                    <li><a href="https://twitter.com/Sentit_official" target="_">Twitter</a></li>
                    <li><a href="https://www.linkedin.com/company/sentit-official" target="_">LinkedIn</a></li>
                    <li><a href="https://t.me/sentit_wallet" target="_">Telegram</a></li>
                    <li><a href="https://web.facebook.com/people/Sentit/100083783012389/" target="_"> Facebook</a></li>
                    <!-- <li><a href="http://scanfcode.com/privacy-policy/">Privacy Policy</a></li> -->
                    <!-- <li><a href="http://scanfcode.com/sitemap/">Sitemap</a></li> -->
                </ul>
            </div>
        </div>
        <hr>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-8 col-sm-6 col-xs-12">
                <!-- <p class="copyright-text">Copyright &copy; 2021 All Rights Reserved by 
       <a href="#">Scanfcode</a>. -->
                <!-- </p> -->
            </div>

            <div class="col-md-4 col-sm-6 col-xs-12">
                <ul class="social-icons">
                    <li><a class="facebook" href="https://web.facebook.com/people/Sentit/100083783012389/" target="_"><i
                                class="fa fa-facebook"></i></a></li>
                    <li><a class="twitter" href="https://twitter.com/Sentit_official" target="_"><i
                                class="fa fa-twitter"></i></a></li>
                    <li><a class="telegram" href="https://t.me/sentit_wallet" target="_"><i
                                class="fa fa-telegram"></i></a></li>
                    <li><a class="linkedin" href="https://www.linkedin.com/company/sentit-official" target="_"><i
                                class="fa fa-linkedin"></i></a></li>



                    <!-- <li><a class="discord" href="https://discord.gg/paApBTDW" target="_"><i class="fab fa-discord"></i></a></li> -->
                </ul>
            </div>
        </div>
    </div>
</footer>
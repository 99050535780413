<app-app-banner></app-app-banner>

<app-app-features></app-app-features>

<app-app-about></app-app-about>

<app-app-faq></app-app-faq>

<app-app-download></app-app-download>

<app-app-contact></app-app-contact>
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-features',
    templateUrl: './app-features.component.html',
    styleUrls: ['./app-features.component.scss']
})
export class AppFeaturesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            title: 'App Features',
            paragraphText: ''
        }
    ]
    singleAppFeatures: FeaturesContent[] = [
        {
            icon: 'las la-wallet',
            title: 'Digital wallet',
            paragraph: 'A single wallet to store your stablecoins, NFTs, and cryptocurrencies.'
        },
        {
            icon: 'las la-paper-plane',
            title: 'Swift transaction',
            paragraph: 'Sentit transactions are exceptionally rapid compared to other cross-border payments, with settlements occurring in less than five seconds.'
        },
        {
            icon: 'las la-lightbulb',
            title: 'Blockchain user friendly',
            paragraph: 'The Sentit platform is suitable for blockchain users of all levels, whether they are new or experienced. The process of using Sentit is as effortless as sending an email.'
        },
        {
            icon: 'las la-random',
            title: 'Cross currency payments',
            paragraph: 'With sentit, you can send cross-asset payments, as fast as possible. NGN -> GHS, EUR -> USD and many more.'
        },
        {
            icon: 'las la-hand-holding-usd',
            title: 'small transaction charges',
            paragraph: "With Sentit's exceptionally low transaction fees, you can easily make cross-border payments without any hassle."
        },
        {
            icon: 'las la-dollar-sign',
            title: 'Simple payment methods.',
            paragraph: 'You may send money to phone numbers, email addresses, federated addresses, and more.'
        },
        {
            icon: 'las la-globe',
            title: 'Fiat on and off ramp',
            paragraph: "To witness the potential of blockchain, deposit funds in your local currency."
        }
    ]

}
class sectionTitleContent {
    title : string;
    paragraphText : string;
}
class FeaturesContent {
    icon : string;
    title : string;
    paragraph : string;
}
<section id="about" class="book-about-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="book-about-content" *ngFor="let Content of aboutContent;">
                    <h3>{{Content.title}}</h3>
                    <p *ngFor="let pList of Content.paragraphList">{{pList.paragraph}}</p>
                    <ul class="book-list">
                        <li *ngFor="let List of Content.featuresList"><i class="{{List.itemIcon}}"></i> {{List.item}}</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="book-about-image" *ngFor="let Image of aboutImage;">
                    <img [src]="Image.img" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
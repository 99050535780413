import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-app-contact',
  templateUrl: './app-contact.component.html',
  styleUrls: ['./app-contact.component.scss'],
})
export class AppContactComponent implements OnInit {
  contactForm: FormGroup;

  constructor(private readonly httpClient: HttpClient) {}

  ngOnInit(): void {
    this.initForm();
  }

  onSendEmail(): void {
    if (this.contactForm.invalid) {
      return;
    }
    const { name, email, phoneNumber, message, subject } =
      this.contactForm.value;
      console.log( name, email, phoneNumber, message, subject )

    const req_data  = JSON.stringify({
      "email":email,
      "mailing_type":"query",
      "subject":subject,
      "message_body":message,
      "phone_number":phoneNumber

    })
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
    });
    this.httpClient
      .post(
        'https://sentit.io/resend_confirmation_email',
        req_data,
        {
          headers: headers,
        }
      )
      .subscribe((res: any) => {
        console.log({ res });
      });
      ////clear input fields
        this.contactForm.reset();
        //set an alert
        alert("Your message has been sent successfully")
  }

  private initForm(): void {
    this.contactForm = new FormGroup({
      name: new FormControl(null, Validators.required),
      subject: new FormControl(null, Validators.required),
      phoneNumber: new FormControl(null, Validators.required),
      email: new FormControl(
        null,
        Validators.compose([Validators.required, Validators.email]),
      ),
      message: new FormControl(null, Validators.required),
    });
  }

  sectionTitle: sectionTitleContent[] = [
    {
      title: 'Get In Touch With Us',
      paragraphText: '',
    },
  ];
  contactImage: Image[] = [
    {
      img: 'assets/img/app-contact.png',
    },
  ];
}
class sectionTitleContent {
  title: string;
  paragraphText: string;
}
class Image {
  img: string;
}
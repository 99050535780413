import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-about',
    templateUrl: './app-about.component.html',
    styleUrls: ['./app-about.component.scss']
})
export class AppAboutComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    aboutImage: Image[] = [
        {
            img: 'assets/img/app-about.jpg'
        }
    ]
    aboutContent = [
        {
            title: 'Sentit facilitates effortless cross-border payments.',
            paragraphList: [
                {
                    paragraph: 'Sentit is a non-custodial blockchain wallet with our main focus on cross-border payments.',
                },
                {
                    paragraph: 'Sentit is powered by the stellar blockchain, which allows for fast, secure transactions with low fees.',
                }
            ],
            featuresList: [
                {
                    itemIcon: "las la-check",
                    item: "Fast"
                },
                {
                    itemIcon: "las la-check",
                    item: "Simple"
                },
                {
                    itemIcon: "las la-check",
                    item: "Cross Currency Payments."
                },
                {
                    itemIcon: "las la-check",
                    item: "Low Transaction Fee."
                },
                {
                    itemIcon: "las la-check",
                    item: "Simple Payment Methods."
                },
                {
                    itemIcon: "las la-check",
                    item: "Fiat On And Off-Ramp."
                }
            ]
        }
    ];

}
class Image {
    img : string;
}
<section class="app-download ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="app-download-content" *ngFor="let Content of appDownloadContent;">
                    <h3>{{Content.title}}</h3>
                    <p>{{Content.paragraph1}}</p>
                    <p>{{Content.paragraph2}}</p>
                    <p>{{Content.paragraph3}}</p>
                    <div class="app-holder">
                        <a href="#"><img [src]="Content.button1" alt="image"></a>
                        <a href="#"><img [src]="Content.button2" alt="image"></a>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-6 col-md-12">
                <div class="app-download-image text-center" *ngFor="let Image of appDownloadImage;">
                    <img [src]="Image.img" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-faq',
    templateUrl: './app-faq.component.html',
    styleUrls: ['./app-faq.component.scss']
})
export class AppFaqComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            title: 'Frequently Asked Questions',
            paragraphText: 'Commonly Asked Questions From Our Users.'
        }
    ]
    accordionItem: Content[] = [
        {
            title: 'How Do I Start Using Blockchain To Send Payments?',
            paragraph: "We are aware that using blockchain for transactions can be a bit complicated at first, so we have streamlined the entire process. Simply sign up and send your first payment—it's that easy!"
        },
        {
            title: 'How Does The Fx On Sentit Work?',
            paragraph: "The fx is managed via a stellar feature called path-payment, which always tries to find you the best rate. Sentit uses the stellar blockchain for all transactions."
        },
        {
            title: 'Which Currencies Are Supported On Sentit?',
            paragraph: "Sentit support series of assets, such as but not restricted to NGN, GHS, KHS, TZS, CNY, ZAR, CAD, USD, etc."
        },
        {
            title: 'How Can I Fund My Account/Wallet?',
            paragraph: "You can fund any account/wallet with either bank transfers or cryptocurrency assets. Keep in mind that each currency has a different approach to funding your wallet."
        },
        {
            title: 'How Secure Is My Fund?',
            paragraph: "You maintain full control over your funds because sentit is powered by stellar blockchain, one of the most secure blockchains around."
        }
    ]

}
class sectionTitleContent {
    title : string;
    paragraphText : string;
}
class Content {
    title : string;
    paragraph : string;
}
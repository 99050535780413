<section id="about" class="saas-about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="saas-about-content" *ngFor="let Content of aboutContent;">
                    <h2>{{Content.title}}</h2>
                    <div class="text" *ngFor="let List of Content.textList">
                        <h3>{{List.title}}</h3>
                        <p>{{List.paragraph}}</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="saas-about-image" *ngFor="let Image of aboutImage;">
                    <img [src]="Image.img" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>